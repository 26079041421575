import React, { useState, useEffect, useReducer } from 'react';
import { Link, useParams } from "react-router-dom";
import { Typography, Row, Col, Table, Avatar, Result, Pagination, message, Button, theme, Divider, Tag, Space } from 'antd';
import { 
    UserOutlined, 
    SwapOutlined, 
    StarFilled,
    EditOutlined,
    CopyrightOutlined,
    EyeInvisibleOutlined
} from '@ant-design/icons';

import { Stat, Loading, FccSection } from 'components';
import { api } from 'api';
import { useChangePageTitle } from 'utils/useChangePageTitle';

import { getErrorMessage } from 'helpers/feedbackMessageHelper';
import { reducer, reducerActions, initialState } from './reducer';
import MyTeamBar from './MyTeamBar';

import styles from './styles';
import ChangeTeamName from './partials/ChangeTeamName';
import TransferHistory from './partials/TransferHistory';

import useUser from "hooks/useUser";
import useScreenSize from "hooks/useScreenSize";
import useAdmin from "hooks/useAdmin";

const { Title, Text } = Typography;
const { useToken } = theme;

export const TeamContext = React.createContext();

const Team = () => {

    let params = useParams();

    const [{ isTablet }] = useScreenSize();
    const changePageTitle = useChangePageTitle();
    const { userData, loggedOut } = useUser();
    const { adminData } = useAdmin();
    const { token } = useToken();

    const [state, dispatch] = useReducer(reducer, initialState);
    const myTeam = ! loggedOut && parseInt(params.teamId) === parseInt(userData?.teamId);
    const nextGameweek = adminData.getGameweek() +1;
    
    const [teamDataLoading, setTeamDataLoading] = useState(true);
    const [teamDataLoadingFirstTime, setTeamDataLoadingFirstTime] = useState(true);
    const [nextPlayersDataLoading, setNextPlayersDataLoading] = useState(true);
    const [changeTeamNameModalIsVisible, setChangeTeamNameModalIsVisible] = useState(false);
    const [transferHistoryModalIsOpen, setTransferHistoryModalIsOpen] = useState(false);

    const [gameweek, setGameweek] = useState(myTeam ? nextGameweek : adminData.getGameweek());

    const getNextPlayers = async () => {

        setNextPlayersDataLoading(true);

        try {
            const result = await api.team.getTransferStatus(params.teamId);

            dispatch({
                type: reducerActions.setNextPlayers, 
                payload: result.data.nextPlayers
            });

        } catch(error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
        }

        setNextPlayersDataLoading(false);
    }

    const changeGameweek = async (newGameweek) => {

        setTeamDataLoading(true);

        try {
            const result = await api.team.getTeamData(params.teamId, newGameweek);

            const teamPlayers = [];
            const transfersIn = [];
            let weekCaptain = '';

            if (result.data.weekTransfers) {
                result.data.weekTransfers.map((transfer) => {
                    transfersIn.push(transfer.playerIn.id);
                });
            }

            if (result.data.teamPlayers) {
                result.data.teamPlayers.map((player) => {
                    player['key'] = player.id;
                    player['transferredIn'] = transfersIn.includes(player.id);

                    if (player.captain) {
                        weekCaptain = player.name;
                    }

                    teamPlayers.push(player);
                });
            }

            if (result.data.deductions && result.data.deductions.length) {
                result.data.deductions.map(deduction => {
                    teamPlayers.push({
                        id: deduction.id,
                        name: "Points Adjustment",
                        weekPoints: deduction.amount,
                        expandable: true,
                        description: `Reason: ${deduction.reason}`
                    });
                });
            }

            dispatch({
                type: reducerActions.setTeamData,
                payload: {
                    teamData: { 
                        ...result.data,
                        teamPlayers: teamPlayers,
                    },
                    weekCaptain: weekCaptain,
                    transfersIn: transfersIn
                }
            });

            changePageTitle(`Team: ${result.data.name}`);
        } catch (error) {
            dispatch({
                type: reducerActions.setTeamData,
                payload: []
            });

            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
        }

        setGameweek(newGameweek);
        setTeamDataLoadingFirstTime(false);
        setTeamDataLoading(false);
    }

    useEffect(() => {

        setTeamDataLoadingFirstTime(true);

        // set initial players
        changeGameweek(myTeam ? nextGameweek : adminData.getGameweek());

        if (myTeam) {
            getNextPlayers();
        } else {
            setNextPlayersDataLoading(false);
        }
    }, [params.teamId, loggedOut]);

    const columns = [
        {
            title: '',
            dataIndex: 'positionName',
            render: (text, record) => adminData.sportIsCricket() && record.positionId ? 
                (<img src={`/images/${record.positionId}.svg`} width="30px" height="30px" />) : 
                <Text type="secondary">{record.positionName}</Text>,
            align: 'right'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => {
                const playerIsCaptain = record.captain;
                return (
                    <>
                        
                        {record.id && !record.expandable ? (
                            <Link to={`/player/${record.id}`}>{record.name} {playerIsCaptain && <CopyrightOutlined />}</Link>
                        ) : (
                            record.name
                        )}

                        {record.teamOfTheWeek && <StarFilled style={{marginLeft: 2, color: token.colorStar }} />}

                        {record.transferredIn && <SwapOutlined style={{marginLeft: 2, color: token.colorSuccess}} />}
                        
                    </>
                )
            },
        },
        {
            title: 'Value',
            dataIndex: 'adjustedValue',
            render: (record) => record ? `${adminData.currency()}${record}m` : ""
        },
        {
            title: `Week Points`,
            dataIndex: 'weekPoints',
            render: (record) => record ? record : 0,
        },
    ];

    const renderGamePaginationItem = (page, type, originalElement) => {
        return type === 'page' && page === adminData.getGameweek() ? <a rel="no-follow">Current</a> : originalElement
    }

    if (teamDataLoadingFirstTime || nextPlayersDataLoading) {
        return <Loading />
    }

    if (!state.teamData || state.teamData.length === 0) {
        return (
            <Result 
                title="Team not found" 
                status="error" 
                />
        )
    }

    return (
        <TeamContext.Provider value={{ state, dispatch }}>
            <Row type="flex" justify="space-between" align="middle">
                <Col xs={24} md={12}>
                    <div style={styles.teamManagerWrap}>
                        <Title 
                            style={styles.teamName} 
                            level={3}
                            >
                            {state.teamData.name}
                        </Title>
                        { myTeam && (
                            <Button 
                                size="small" 
                                shape="circle" 
                                icon={<EditOutlined />} 
                                style={{ marginLeft: 8 }} 
                                onClick={() => setChangeTeamNameModalIsVisible(true)}
                                /> 
                        )}
                    </div>
                    <div style={styles.teamManagerWrap}>
                        <Avatar 
                            size="small" 
                            shape="square" 
                            icon={<UserOutlined />} 
                            style={{ marginRight: 4 }}
                            /> 
                        <Text type="secondary">
                            {state.teamData.teamManager}
                        </Text>
                    </div>
                    
                </Col>
                <Col xs={24} md={12} style={styles.gameweekSelect(isTablet)}>
                    <Pagination 
                        size="small"
                        defaultCurrent={gameweek} 
                        total={myTeam ? adminData.getGameweek() +1 : adminData.getGameweek()} 
                        showLessItems 
                        defaultPageSize={1} 
                        showTotal={() => `View gameweek:`}
                        onChange={value => changeGameweek(value)}
                        disabled={adminData.seasonHasNotStarted()}
                        itemRender={renderGamePaginationItem}
                        />
                </Col>
            </Row>

            <Divider plain={false} style={{backgroundColor: token.colorPrimary}} />
            
            {myTeam && <MyTeamBar teamId={params.teamId} gameweek={gameweek} /> }

            {adminData.seasonHasNotStarted() && !myTeam ? (
                <Result 
                title="Unauthorised Access" 
                subTitle="You cannot view another team before the season has started"
                status="warning" 
                icon={<EyeInvisibleOutlined />}
                />
            ) : (
                <>
                    <FccSection>
                        <Table 
                            rowKey={record => record.id}
                            columns={columns} 
                            dataSource={state.teamData.teamPlayers} 
                            size="middle" 
                            pagination={false} 
                            expandable={{
                                expandedRowRender: record => <p style={{ margin: 0 }}>{record.description}</p>,
                                rowExpandable: record => record.expandable
                            }}
                            loading={teamDataLoading}
                            summary={() => {
                                return (
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0} colSpan={4}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={1}>
                                            <Space size={4}>
                                                <Text strong>{state.teamData.weekPoints}</Text>
                                                <Text type="secondary">({state.teamData.transferDeduction})</Text>
                                            </Space>
                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                )
                            }}
                            />

                        {(gameweek === state.teamData.wildcardGameweek || gameweek === state.teamData.tripleCaptainGameweek) && (
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '20px'}}>

                                {(state.teamData.wildcardGameweek && gameweek === state.teamData.wildcardGameweek) && (
                                    <Tag icon={<StarFilled />} color="success">
                                        Wildcard Played
                                    </Tag>
                                )}

                                {(state.teamData.tripleCaptainGameweek && gameweek === state.teamData.tripleCaptainGameweek) && (
                                <Tag icon={<StarFilled />} color="success">
                                    Triple Captain Active
                                </Tag>
                                )}
                                
                            </div>
                        )}
                    </FccSection>

                    <FccSection>
                        <Row type="flex" >
                            <Col xs={24} sm={8}>
                                <Stat type="blank" title="TOTAL POINTS" value={state.teamData.totalPoints} loading={teamDataLoading} />
                            </Col>
                            <Col xs={24} sm={8}>
                                <Stat type="blank" title={`WEEK ${gameweek} POINTS`} value={state.teamData.weekPoints} loading={teamDataLoading} />
                            </Col>
                            <Col xs={24} sm={8}>
                                <Stat
                                    type="blank"
                                    title="TRANSFERS MADE" 
                                    value={state.transfersIn.length} 
                                    loading={teamDataLoading} 
                                    link={
                                        <Button 
                                            type="link" 
                                            onClick={() => setTransferHistoryModalIsOpen(true)}
                                            >
                                            Transfer History
                                        </Button>
                                    }
                                    />
                            </Col>
                        </Row>
                    </FccSection>

                    {myTeam && 
                        <ChangeTeamName 
                            isVisible={changeTeamNameModalIsVisible} 
                            handleClose={() => setChangeTeamNameModalIsVisible(false)} 
                            />
                    }

                    <TransferHistory
                        showModal={transferHistoryModalIsOpen}
                        teamId={params.teamId} 
                        handleClose={() => setTransferHistoryModalIsOpen(false)}
                        />
                </>
            )}
            
        </TeamContext.Provider>
    )
}

export default Team;